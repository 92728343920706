<template>
    <div>
        <div class="card-toolbar mb-5">
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="name">{{$t('chart_account_list.name')}}</label>
                            <input v-model="filters.name" type="text" id="name" class="form-control">
                        </div>


                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="parent_id">
                                {{$t('chart_account_list.parent_name')}}
                            </label>
                            <treeselect
                                    id="parent_id"
                                    :options="accounts_list"
                                    :load-options="loadOptions"
                                    :multiple="false"
                                    :value="filters.parent_id"
                                    @input="updateValue"
                                    :searchable="true"
                                    :show-count="true"
                                    :no-children-text="$t('No_sub_options')"
                                    :no-options-text="$t('No_options_available')"
                                    :no-results-text="$t('No_results_found')"
                                    :placeholder="$t('Select')">
                            </treeselect>
                        </div>
                        <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
                            <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i> {{$t('search')}}</b-button>
                            <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">


                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="actions" slot-scope="props">
                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" color="blue darken-2" v-if="$can('chart_accounts.update')" @click="editItem(props.row)">mdi-pencil</v-icon>
                        <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" color="red darken-2" v-if="$can('chart_accounts.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
                    </template>
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-groups-accounts",
        data() {
            return {
                mainRoute: 'accounting/chart-account/same_level',
                mainRouteDependency: 'base/dependency',
                mainRouteForChart: 'accounting/chart-accounts',

                showAdvancedSearch: false,
                dataList: [],
                accounts_list: [],
                filters: {
                    name: null,
                    parent_id: this.$route.params.parent_id ? this.$route.params.parent_id : null,
                },



                columns: ['name', 'code', 'parent_name', 'amount', 'currency_name', 'type_account_name', 'natural_account_name', 'custom_type_name', 'actions'],
            }
        },

        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('groups_accounts.name')] = 'name';
                fields[this.$t('groups_accounts.created_at')] = 'created_at';

                return fields;
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        name: that.$t('chart_account_list.name'),
                        code: that.$t('chart_account_list.code'),
                        parent_name: that.$t('chart_account_list.parent_name'),
                        amount: that.$t('chart_account_list.amount'),
                        currency_name: that.$t('chart_account_list.currency'),
                        type_account_name: that.$t('chart_account_list.type_account'),
                        natural_account_name: that.$t('chart_account_list.natural_account'),
                        custom_type_name: that.$t('chart_account_list.custom_type'),
                        actions: that.$t('actions'),

                    },
                    sortable: [],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: 0,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    },

                }

            },
        },
        mounted() {
            // let that = this;
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.chart_account_list")}]);
            this.getAccountParents();
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.name = null;
                this.filters.parent_id = null;

                this.$refs.table.refresh();
            },
            updateValue(value) {
                this.filters.parent_id = value
            },
            loadOptions() {
            },
            getAccountParents() {
                ApiService.get(`${this.mainRouteDependency}/account_without_simple`).then((response) => {
                    this.accounts_list = response.data.data;
                });
            },

            actionDelete(item) {
                ApiService.delete(this.mainRouteForChart + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message);
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },
            editItem(item) {
                this.$router.push({name: 'chart-accounts.edit', params: {id: item.id}});
            },
        },
    };
</script>
